.orgContainer {
  position: relative;
  background: #fafafa;
  margin-bottom: 30px;
  border-radius: 15px;
  padding: 10px;
}

.orgSection {
  display: flex;
  margin-bottom: 15px;
  border-radius: 15px;
  padding: 10px 40px;
  @media (max-width: 1030px) {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
  @media (max-width: 767px) {
    padding: 0;
  }
}

.orgDetailsContainer {
  position: relative;
  display: grid;
  grid-template-columns: 45% 20% 20%;
  flex-direction: row;
  align-items: center;
  flex: 1;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.orgDetailsChildContainer {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  flex: 1;
  /* justify-content: flex-start; */
}

.viewDashboardButtonContainer {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: space-between;
  align-self: flex-start;
}

.orgImageWrapper {
  margin-right: 30px;
}

.orgImage {
  width: 120px;
  max-height: 120px;
}

.orgImage:hover {
  cursor: pointer;
}

.locationInfoWrapper {
  height: 100%;
}

.locationName {
  color: #50618b;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 15px;
}

.locationAddressWrapper {
  margin-bottom: 10px;
}

.locationAddressWrapper > div {
  line-height: 2rem;
}

.locationPhoneWrapper {
  color: #29a096;
  font-weight: bold;
}

.gadgetInfoSummary {
  font-family: Montserrat;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4rem;
  letter-spacing: 0;
  text-align: left;
  padding-left: 30px;
}

.collapseWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: left;
  align-items: center;
  cursor: pointer;
}

.responseWrapper {
  color: #747474;
  display: flex;
}

.timeReport {
}

.timeReportLabel {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  color: #445276;
  margin-bottom: 5px;
}

.timeReportValueWrapper {
  display: flex;
  align-items: center;
}

.timeReportIndicatorDot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  margin-right: 13px;
}

.timeReportValue {
  font-family: Montserrat;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.9rem;
  letter-spacing: 0;
  text-align: left;
}
/* COLLAPSE CSS STYLES */

.arrowsIcon {
  color: #747474;
  padding-right: 10px;
}

.gadgetContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 35px 30px;
  visibility: visible;
  opacity: 1;
  animation: fade 1s;
  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.collapseGadgetContainer {
  display: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 600ms, visibility 600ms;
}
