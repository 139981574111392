.summaryContainer {
  color: #50618b;
  display: flex;
  margin-bottom: 15px;
}

.summaryWrapper {
  align-items: center;
  display: flex;
  margin-right: 20px;
}

.iconStyle {
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

/* LOADER STYLES */

.headerWrapper {
  margin-bottom: 20px;

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: row;
  }
}

.textInfoLoaders {
  margin-left: 30px;
}

.textLabelLoaders {
  width: 50px !important;
  height: 10px !important;
}

.hotelIconLoader {
  width: 25px !important;
  height: 25px !important;
  margin-right: 25px !important;
}

.healthIconLoader {
  border-radius: 50% !important;
  width: 25px !important;
  height: 25px !important;
  margin-right: 25px !important;
}

.tableFilterLoaders {
  width: 200px !important;
  height: 50px !important;
  border-radius: 10px !important;
  margin-right: 30px;
  margin-bottom: 30px;
}

.orgCardLoader {
  height: 200px !important;
  margin-bottom: 30px;
  border-radius: 10px !important;
}
