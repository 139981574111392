.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-face-montserrat {
  font-family: 'Montserrat';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 2rem;
}

.MuiSvgIcon-root {
  font-size: 2.4rem;
}

.MuiButton-root,
.MuiTableCell-root,
.MuiTab-root {
  font-size: 1.4rem;
}

.MuiInputBase-root,
.MuiMenuItem-root,
.MuiAutocomplete-paper {
  font-size: 1.6rem;
}

.MuiRadio-root {
  color: #50618b;
}

.MuiMenuItem-root {
  text-transform: capitalize;
}

.MuiTooltip-tooltip {
  font-size: 1.2rem;
}
