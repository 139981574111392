:root {
  font-size: 62.5%;
  --color-primary: #50618b;
  --color-primary-highlight: #2047aa;
  --color-secondary: #747474;
  --color-gray-1: #cecece;
  --color-gray-2: #e6e6e6;
  --color-gray-3: #fafafa;
  --color-gray-4: #eaeaea;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-error: #f44336;
  --color-invalid: #ff5630;
  --color-warning: #ffbf3e;
  --color-success: #5fd078;
  --color-text-link: #29a096;
  --color-textfield-focus: #30bcb0;
  --color-textfield-label: #4e5d78;
  --color-table-row-hover: #f5f4f4;
  --color-table-row-selected: #fffdf0;
  --color-linear-progress-1: #b1b8c9;
  --color-linear-progress-2: #4e5d78;
  --color-brand: #30bcb0;
}

body {
  margin: 0;
  font: normal 1.6rem/1.2 Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('./static/fonts/Montserrat-Regular.ttf') format('truetype');
}
