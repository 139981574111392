.gadgetInfoWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  font-size: 1.2rem;
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin: 5px 5px;
  flex: 1 0 19%;
  @media (max-width: 1300px) {
    flex: 1 0 29%;
  }
}

.orgName {
  margin-bottom: 15px !important;
}

.gadgetLabelWrapper {
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}

.gadgetLabelWrapper:nth-child(4) {
  margin-bottom: 15px;
}

.highlightIconStyle {
  color: #f44336;
  padding-right: 10px;
}

.circleIconStyle {
  color: #e89f0b;
  padding-right: 10px;
}

.checkCircleIconStyle {
  color: #5fd078;
  padding-right: 10px;
}

.viewDetailButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.detailButton {
  background: #bcf8f2 !important;
  color: #1d716a !important;
  width: 100% !important;
  border-radius: 15px !important;
  text-transform: none !important;
  font-family: Montserrat !important;
  font-weight: 600 !important;
}
